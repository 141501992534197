.bootstrap-select {
	width: 100% !important;

	&>select {
		pointer-events: none;
	}

	.dropdown-toggle {
		display: flex !important;
		padding: 12px 20px !important;
		border: 1px solid $navy !important;
		border-radius: 8px !important;
		color: $navy !important;
		font-weight: 600 !important;
		font-size: 1.6rem !important;

		&:hover {
			background-color: $navy !important;
			color: $white !important;
		}

		.filter-option-inner-inner {
			margin-right: 24px !important;
			text-transform: none;
		}

	}

	.dropdown-menu {
		li {
			margin-right: 0 !important;
			font-size: 1.6rem !important;

			a {
				font-size: 1.6rem !important;

				&.active,
				&:active {
					background: $navy;
				}

				.text {
					font-weight: 600 !important;
					font-size: 1.6rem !important;
				}
			}
		}
	}
}